document.addEventListener('DOMContentLoaded', function() {
  const experience = document.getElementById('experience');
  const aboutMe = document.getElementById('about-me');
  const articleExperience = document.querySelector('.experience');
  const articleAboutMe = document.querySelector('.about');

  function toggleSections() {
    if (experience.classList.contains('active')) {
      articleExperience.classList.add('expanded');
      articleAboutMe.classList.remove('expanded');
    } else if (aboutMe.classList.contains('active')) {
      articleExperience.classList.remove('expanded');
      articleAboutMe.classList.add('expanded');
    } else {
      articleExperience.classList.remove('expanded');
      articleAboutMe.classList.remove('expanded');
    }
  }

  // Initial call to set the visibility based on the initial state
  toggleSections();

  // Add click event listeners to the elements with the 'active' class
  experience.addEventListener('click', function() {
    experience.classList.add('active');
    aboutMe.classList.remove('active');
    toggleSections();
  });

  aboutMe.addEventListener('click', function() {
    aboutMe.classList.add('active');
    experience.classList.remove('active');
    toggleSections();
  });
});
